import axios from 'axios';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

function Policiales() {
  
  const [data, setData] = useState([]);
  
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  useEffect(() => {

    const getData = async () => {
      const result = await axios.get(process.env.REACT_APP_POLICIALES)
      setData(result.data);
    }

    getData();

  }, [])

    return (
    <section className='w-100 mt-2' id="container">

      <div id="wrapper-category" className='w-[180px] md:h-12 bg-red-600'>
        <h2 className='text-white font-bold flex justify-center relative left-2 p-2 text-xl'>Policiales</h2>
      </div>

      <section className='md:flex md:gap-4 h-auto w-100'>

      {data ? data.slice(0,3).map((e) => 
      <div key={e._id} className={isDesktop ? "my-2 p-3 md:w-2/6 shadow rounded-xl h-auto" : "shadow my-3 p-3 w-100 rounded"}>
        <Link to={e.Entry_Category + '/' + e._id}>
          <img src={e.Entry_Featured_Image} className="w-100 object-cover h-64" alt={e.Entry_Title + '-img'}/>
        </Link>
        
        <span className='relative -top-14 float-right -left-3 bg-red-600 p-2 text-sm text-white'>
          <p>{e.Entry_Category}</p>
        </span>

        {/* <p className="text-xs text-neutral-400 py-1.5"> <FontAwesomeIcon icon={faClock}/>  {e.createdAt.slice(0, 10).replaceAll('-', '/')}</p> */}

        <Link to={e.Entry_Category + '/' + e._id} className="hover:text-[#604595]">
          <h2 
            className={`font-black ${isDesktop ? 'text-xl w-100 py-2' : 'text-md w-100 py-1'}`}
            >
              {e.Entry_Title}
          </h2>
        </Link>

        <h3 className={isDesktop ? 'text-md my-1' : 'text-sm my-1'} id="card__preview-text">{e.Entry_Resume}</h3>

        <Link to={ e.Entry_Category + '/' + e._id}>
          <button className='text-white bg-red-600 p-1.5 rounded mt-2 text-sm'>Leer Más...</button>
        </Link>

      </div>) : null }
        </section>

    </section>
  )
}

export default Policiales