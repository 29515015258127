import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
function EditarCorteCalf() {
  const navigate = useNavigate();
  /*Getting User Token*/
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const capitalize =  user.userData.username.replace(/^\w/, c => c.toUpperCase())
  /*Handling Selection*/
  const [cortes, setCortes] = useState([]);
  const [selectedCorte, setSelectedCorte] = useState(null);
  /*Getting Literals*/
  const [title, setTitle] = useState('');
  const [id, setId] = useState('');
  const [url, setUrl] = useState('');
  /*Handling Changes*/
  const [newTitle, setNewTitle] = useState('');
  const [newUrl, setNewUrl] = useState('');
  /*Setters*/
  const handleTitle = (e) => {setTitle(e.target.value)}
  const handleUrl = (e) => {setUrl(e.target.value)}

  useEffect( () => {
    fetch(process.env.REACT_APP_CORTES_API_URL)
    .then(response => { return response.json()} )
    .then(data => { setCortes(data)})
    .catch(err => {console.log(err)} )
  },[]);
  
  const handleSelectChange = (event) => {
    const corteId = event.target.value;
    const selected = cortes.find((corte) => corte.id === corteId);
    setSelectedCorte(selected || {});
    setTitle(selected ? selected.title : '');
    setId(selected ? selected._id : '');
    setUrl(selected ? selected.url : '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      title,
      id,
      url
    }
    console.log(data)
    console.log(id)
    fetch(process.env.REACT_APP_CORTES_API_URL + '/' + id, {method: 'PUT', mode: "cors", redirect: 'follow' ,headers:  {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'x-access-token': user.token}, body: JSON.stringify(data)})
    .then(response => { console.log('response', response); navigate(0); if(response.state == 200){ alert('Aprobado') }})
    .catch(e => { console.log('e', e) })
  }

  const handleClickDelete = async (e) => {
    await fetch(process.env.REACT_APP_CORTES_API_URL + '/' + id, {method: 'DELETE', headers: { 'x-access-token': user.token }})
      .then((response) => {
        if(!response.ok){ throw new Error('Something went wrong')}
        navigate(0);
      })
      .catch((e) => { console.log(e) })
    }

  return (
    <section>
    <section className="p-1 outline outline-1 w-auto flex flex-wrap">
    {cortes && cortes.length <= 0 ? <h2> No hay cortes programados para ésta semana</h2> : cortes.map((e) =>
    <div key={e.id} className="p-2 m-4 outline outline-1 text-center font-serif font-semibold text-xl w-1/5">
      <img className="h-[200px] w-max" src={e.url}/>
      <p>{e.title}</p>
    </div>
    )}
  </section>
  {/* EDITOR DECORTES */}
  <section className="p-2 text-center">
    <form onSubmit={handleSubmit}>
      <h3 className="p-2 text-xl font-sans">Editar cortes</h3>
      {/* OPTIONS */}
      <div className="flex justify-center">
        <select
          className="w-25 outline outline-1"
          value={selectedCorte ? selectedCorte.id : ""}
          onChange={handleSelectChange}
        >
        {cortes && cortes.length <= 0 ? (
          <option>No hay elementos</option>
        ) : (
          cortes.map((e) => (
          <option key={e.id} value={e.id}>
            {e.title}
          </option>
          ))
        )}
        </select>
      </div>

      {selectedCorte && cortes.length > 0 ? ( // Verificar si selectedCorte y cortes tienen datos
        <div className="m-4 block bg-red">
          <label htmlFor="title" className="p-2">
            Titulo
          </label>
          <input
            type="text"
            name="title"
            value={title}
            className="outline outline-1 w-25 p-1"
            onChange={handleTitle}
          />
          <label htmlFor="url" className="p-2">
            Url
          </label>
          <input
            type="text"
            name="url"
            value={url}
            className="outline outline-1 w-25 p-1"
            onChange={handleUrl}
          />

          <div className="flex justify-center">
            <button className="p-2 bg-blue-400 text-white rounded-md m-2">
              Editar
            </button>
            <button className="p-2 bg-red-400 text-white rounded-md m-2" onClick={handleClickDelete}>
              Eliminar
            </button>
          </div>
        </div>
      ) : (
        <p>Selecciona un corte para editar</p>
      )}
    </form>
    </section>
  </section>
  )
}

export default EditarCorteCalf