import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


function SectionItem() {

  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const result = await axios.get(process.env.REACT_APP_LAST);
      setData(result.data.slice(5, 10));
    }
    getData();
  }, [])
  
  return (
    <section className='w-100 sticky mb-4 mt-4' id="container">
      <div id="wrapper-category" className='md:w-[240px] w-[240px] md:h-12 bg-purple-600'>
        <h2 className='text-white font-bold py-2 flex justify-center relative left-3 text-xl'>Título del bloque</h2>
      </div>
      <div className='mt-4 w-100'>
        <ul className=''>
        {data.map((e) => 
        
          <li className='md:flex md:h-60 w-100 mt-4' key={e._id}>
            <div className='h-[240px] md:w-1/2'>

              <Link to={e.Entry_Category + '/' + e._id} className="h-100 w-100">
                <img 
                  className='object-fit h-100 w-100'
                  src={e.Entry_Featured_Image}
                  alt={e.Entry_Title}/>
              </Link>

              <span className="relative -top-16 float-right right-2 bg-purple-800 p-1.5 text-xs text-white">
                {e.Entry_Category}
              </span>
            </div>

          <div className='w-100 md:px-4'>
            {/* <p className='text-xs text-neutral-400 py-1.5'> <FontAwesomeIcon icon={faClock}/> {e.createdAt.slice(0, 10).replaceAll('-', '/')}</p> */}
            <Link to={e.Entry_Category + '/' + e._id}>
              <p className='text-2xl'>{e.Entry_Title}</p>
            </Link>
            <p className='my-2' id="card__preview-block">{e.Entry_Resume}</p>
            <Link
             to={e.Entry_Category + '/' + e._id}
             className="block mt-2 "
            >
              <button className='p-2 text-white text-sm bg-purple-600'>Leer más »</button>
            </Link>
          </div>
        </li>
        ) }
        </ul>
      </div>
    </section>
  )
}

export default SectionItem