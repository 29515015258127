import axios from 'axios';
import { useState, useEffect } from "react";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer.jsx";
import Header from "../../components/Home/HeaderTest.jsx";
import PostDetail from "./PostDetail";
import Publicidades from "../../components/Ads/Publicidades";
import Banner from './../../assets/data/banner.gif';


function PostContainer() {

  const { id, category } = useParams();
  const [ myObj, setMyObj ] = useState({});

  useEffect(() => {
    const fetchData = () => {
      axios.get(`${process.env.REACT_APP_POST_BY_ID}/${id}`)
        .then((response) => {
          setMyObj(response.data);
        })
        .catch((error) => {
          console.error('Error fetching post:', error);
        });
    };
  
    fetchData();
  }, [id, category]);

  

  return (
    <section>
      <div>

          {/* <title>{`VoxDiario | ${TITLE}`}</title> */}
          {/* <meta name="description" content={DESCRIPTION}  /> */}
          {/* <meta property="og:title" content={TITLE} /> */}
          {/* <meta property="og:description" content={DESCRIPTION} />
          <meta property="og:image" content={IMAGE} />
          <meta property="og:type" content={"article"} /> */}
      </div>
      <Header />
      <section className="container-sm mt-36">
        <Publicidades imagen={Banner} />
        <section className="h-auto">
          { myObj ? myObj["_id"] === id && (
                <section key={myObj["_id"]} className="h-full">
                  
                  <ul className="flex text-[#604595] font-bold py-2">
                    <li className="p-1">
                      {" "}
                      <Link to="/">
                        {" "}
                        <FontAwesomeIcon icon={faHome} className="text-sm" /> Inicio{" "}
                      </Link>{" "}
                      /
                    </li>{" "}
                    <li className="p-1">
                      {" "}
                      <Link to={"/search/" + myObj["Entry_Category"]}>
                        {" "}
                        {myObj["Entry_Category"]}{" "}
                      </Link>{" "}
                    </li>{" "}

                  </ul>

                  <PostDetail key={myObj["_id"]} myObj={myObj} />
                </section>
              ) 
          : null } 
          
        </section>
        {/* Resto del código */}
      </section>

      {/* { products && <Footer />} */}
      {/* { post ? <Footer /> : <> <div className="h-[310px]"></div> <Footer /> </> } */}

      <Footer></Footer>
    </section>
  );
}

export default PostContainer;
