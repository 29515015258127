function BannerAdDos(props) {

  const {imagen} = props;
  return (
    <section className="md:h-40 rounded w-100" id="container">
      <a href="https://www.neuquencapital.gov.ar/">
        <img src={imagen} className="flex justify-center m-auto object-cover h-full w-100" alt="publicidad"/>
      </a>
      
    </section>
  )
}

export default BannerAdDos