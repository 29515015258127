import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function OpenGraph ({ title, description, imageUrl, url, type }) {

    return (

    <Helmet>
        {/* DEFAULT OG */}
        <meta charset="utf-8" />
        <title>{`VoxDiario | ${title}`}</title>
        <meta name="description" content={description}/>

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={`VoxDiario | ${title}`} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${imageUrl}`} />
        <meta property="og:locale" content="es_AR" />
        <meta property="og:site_name" content="VoxDiario" />

        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={`VoxDiario | ${title}`} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={imageUrl} />
        <meta property="twitter:url" content={window.location.href}/>
        <meta name="twitter:site" content="@VoxDiario" />
        <meta name="twitter:creator" content="@VoxDiario" />
    </Helmet>
    )
}