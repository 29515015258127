import { useState } from "react";
import { useNavigate } from "react-router-dom";

function CreateCorteCalf() {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("userInfo"));
  const capitalize =  user.userData.username.replace(/^\w/, c => c.toUpperCase())

  const [title, setTitle] = useState('');
  const [id, setId] = useState('');
  const [url, setUrl] = useState('');

  const handleTitle = (e) => { e.preventDefault(); setTitle(e.target.value) };
  const handleId = (e) => {e.preventDefault(); setId(e.target.value)};
  const handleUrl = (e) => {e.preventDefault(); setUrl(e.target.value)};

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        title,
        id,
        url
    }
      fetch(process.env.REACT_APP_CORTES_API_URL, {method: 'POST', mode: "cors", redirect: 'follow' ,headers:  {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', 'x-access-token': user.token}, body: JSON.stringify(data)})
      .then(response => { console.log('response', response); navigate(0); if(response.state == 200){ alert('Aprobado') }})
      .catch(e => { console.log('e', e) })
  }


  return (
    <section className='flex justify-center'>
      <form className='text-center'>
      <h2>Crear corte</h2>

      <label>Título</label>
      <input
        type="text"
        className='outline placeholder:p-2  outline-slate-300 outline-1 m-2 block justify-center'
        placeholder='titulo'
        onChange={handleTitle}
      />

      <label>Id</label>
      <input
        type="number"
        className='outline placeholder:p-2 outline-slate-300 outline-1 m-2 block'
        placeholder='id'
        onChange={handleId}
      />

      <label>URL</label>
      <input
        type="text"
        className='outline placeholder:p-2 outline-slate-300 outline-1 m-2 block'
        placeholder='url de la imagen'
        onChange={handleUrl}
        />

      <button
        className='p-2 bg-blue-400 rounded mt-2 text-white'
        onClick={handleSubmit}
        >Crear Corte
      </button>

      </form>
    </section>
  )
}

export default CreateCorteCalf