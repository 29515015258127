import { useState, useEffect } from 'react'
  /* <===== Calf Ad =====> */
  import Calf from './../../assets/img/pauta.gif';
  import Banner from './../../assets/img/banner.gif';

  import BannerPauta from './../../assets/img/bannersorteo.jpg';

export default function BannerAd() {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let handleWindowResize = () => { setWindowWidth(window.innerWidth);} ;
   useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <section className='h-100 w-100'>
    {
      windowWidth >= 768 ? 

      <div className="md:w-[50%] md:h-40 h-100 my-3 flex justify-center m-auto w-100 ">
        <a href="https://www.cooperativacalf.com.ar/" className='w-100'>
          <img src={BannerPauta} className="flex justify-center m-auto w-full h-100 outline outline-2 outline-gray-300" alt="publicidad"/>
        </a>
      </div>

      :

      <div className=" flex justify-center m-auto w-100 rounded">
        <a href="https://www.cooperativacalf.com.ar/" className='w-100'>
          <img src={Banner} className="flex justify-center m-auto w-100 h-full outline outline-2 outline-gray-300 object-contain md:object-contain" alt="publicidad"/>
        </a>
      </div>
    }

</section>
  )
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight }
}