import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import Loader from '../Admin/Loader.jsx';
import ErrorMessage from '../Others/ErrorMessage.jsx';
import { useNavigate } from 'react-router-dom';
export default function Test () {
  /*========== Date ==========*/
  /*----- Arrs -----*/
  const week = [{day: "Lunes",date: 1},{day: "Martes",date: 2},{day: "Miercoles", date: 3},{day: "Jueves", date: 4},{day: "Viernes",date: 5},{day: "Sábado",date: 6},{day: "Domingo",date: 7}];
  const calendar = [ {name: "Enero", date: 0},{name: "Febrero",date: 1},{name: "Marzo",date: 2},{name: "Abril",date: 3},{name: "Mayo",date: 4},{name: "Junio",date: 5},{name: "Julio",date: 6},{name: "Agosto",date: 7},{name: "Septiembre",date: 8},{name: "Octubre",date: 9},{name: "Noviembre",date: 10},{name: "Diciembre",date: 11},]
  /*----- Fn -----*/
  const current = new Date();
  /*Days*/
  const GETDAY = () => { return current.getDay() };
  const dayFiltered = week.map((e) => e).filter((e) => e.date === GETDAY());
  /*Months*/
  const GETMONTH = () => { return current.getMonth() };
  const monthFiltered = calendar.map((e) => e).filter((e) => e.date === GETMONTH())

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate =  useNavigate();

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");

    if ( userInfo){
      navigate('/admin')      
    }

  }, [navigate])

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    try {
      const config = {
        headers:{
          "Content-type": "application/json"
        },
      };
        setLoading(true);
        const { data } = await axios.post(process.env.REACT_APP_LOGIN_API_URL, {email, password});
        localStorage.setItem('userInfo', JSON.stringify(data));
        setLoading(false);
        navigate('/admin')
        

    } catch (error) {

      setError(error.response.data.message.data);
      setLoading(false);
      
    }}
return(
  <>
  <div className="block p-6 rounded-lg shadow-lg bg-white max-w-sm m-auto mt-5">
  <form onSubmit={handleSubmit}>
    {error && <ErrorMessage>{error}</ErrorMessage>}
    <div className="form-group mb-6">
      <label htmlFor="exampleInputEmail1" className="form-label inline-block mb-2 text-gray-700">Email address</label>
      <input type="email" className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInputEmail1"
        aria-describedby="emailHelp" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} value={email}/>
      <small id="emailHelp" className="block mt-1 text-xs text-gray-600">We'll never share your email with anyone
        else.</small>
    </div>
    <div className="form-group mb-6">
      <label htmlFor="exampleInputPassword1" className="form-label inline-block mb-2 text-gray-700">Password</label>
      <input type="password" className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInputPassword1" placeholder="Password"
      value={password} onChange={(e) => setPassword(e.target.value)}/>
    </div>
    <button type="submit" className=" px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">Submit</button>
        {loading && <Loader/>}
  </form>
</div>

  </>
)
};