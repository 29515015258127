import React from 'react'

function ErrorMessage({children}) {
  return (
    <div className="bg-red-100 rounded-lg py-3 px-3 mb-4 text-base text-red-700" role="alert">
      <strong>{children}</strong>
    </div>
  )
}

export default ErrorMessage