import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function CarouselCortes({ cortes }) {
  const showArrows = cortes.length > 1;

  const renderStatusFormatter = () => null;

  const renderArrowPrev = (clickHandler, hasPrev, label) => (
    <button
      type="button"
      className="carousel-arrow carousel-prev"
      onClick={clickHandler}
      disabled={!hasPrev}
      aria-label={label}
      style={{ opacity: 0.5 }}
    >
      &#9664;
    </button>
  );

  const renderArrowNext = (clickHandler, hasNext, label) => (
    <button
      type="button"
      className="carousel-arrow carousel-next"
      onClick={clickHandler}
      disabled={!hasNext}
      aria-label={label}
      style={{ opacity: 0.5 }}
    >
      &#9654;
    </button>
  );

  return (
  <>
        {cortes.length > 0 ? (
          <>
          <p className='text-center underline underline-offset-2'>Cortes programados para esta semana</p>
          <Carousel
          autoPlay={true}
          infiniteLoop={true}
          showArrows={showArrows}
          showThumbs={false}
          interval={7000}
          showStatus={false}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}
          renderStatusFormatter={renderStatusFormatter}
          className="custom-carousel m-2"
          dotClass="custom-dot"
          dotActiveClass="custom-dot-active"
          >
          {cortes.map((corte) => (
            <div key={corte.id}>
              <img src={corte.url} alt={corte.title} />
            </div>
          ))}
          </Carousel>
          </>
      ) : (
        <>
        <p className='text-center underline underline-offset-2'>No hay cortes programados para esta semana</p>
        <a href="https://www.cooperativacalf.com.ar/category/cortes-programados/">
          <img src="https://pbs.twimg.com/media/FvuRYzKWYAAa44n?format=jpg&name=large"/>
        </a>
        </>
      )}
  </>
  );
}

export default CarouselCortes;
