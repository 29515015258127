import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { faHome, faRectangleAd, faRightFromBracket, faThumbTack, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from 'react-responsive';

import Post from "./Posts";
import Dashboard from "../../components/Dashboard/Dashboard";
import AdsManager from "../Sidebar/AdsManager";

export default function Ui(){

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const capitalize =  user.userData.username.replace(/^\w/, c => c.toUpperCase())

  const [active, setActive] = useState('firstPage');
  
  const handleLogout =  (e) => {
    e.preventDefault();
    localStorage.removeItem("userInfo");
    navigate('/')
  }

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });


  return(
  <section>
  
  {/* USER INTERFACE */}
  <div className={isDesktop ? "flex h-auto  w-100 justify-between" : "relative h-auto w-100"}>
    <div className={"relative bg-[#2D2D2D]"}>
      {/* bg-[#2D2D2D] */}
    <section className={isDesktop ? "h-100 w-1/5 text-white fixed bg-[#2D2D2D]" : 'w-screen absolute bg-[#2D2D2D] text-white top-0'}>
      <ul className={ isDesktop ? "w-100 relative top-10" : 'w-100 relative h-[225px]'}>

        {/* HOME */}
        <li className="text-xl w-100 flex justify-center mt-2">
          <button onClick={() => setActive('firstPage')} className="active:bg-blue-800 focus:bg-blue-600 duration-100 delay-5 hover:bg-[#6D6D6D] w-60 flex rounded px-2">
            <FontAwesomeIcon icon={faHome} className="p-1 my-1.5 text-xl"/>
            <span className="my-1 py-1 px-2"> Inicio </span>
          </button>
        </li>

        {/* MESSAGES */}
        <li className="text-xl w-100 flex justify-center mt-1">
          <button onClick={() => setActive('thirdPage')} className="active:bg-blue-600 focus:bg-blue-800 hover:bg-[#6D6D6D] w-60 flex rounded px-2">
            <FontAwesomeIcon icon={faRectangleAd} className="p-1 my-1.5 text-xl"/>
            <span className="my-1 py-1 px-1"> Publicidades </span>
          </button>
        </li>

        {/* DASHBOARD */}
        <li className="text-xl w-100 flex justify-center mt-1">
          <button onClick={() => setActive('fourthPage')} className="active:bg-blue-600 focus:bg-blue-800 hover:bg-[#6D6D6D] w-60  flex rounded px-2">
            <FontAwesomeIcon icon={faThumbTack} className="p-1 my-1.5 text-xl"/>
            <span className="my-1 py-1 px-2"> Entradas </span>
          </button>
        </li>

        {isDesktop ? <br></br> : null}
        <hr className="mx-3 mb-3"/>

        {/* LOG OUT */}
        <li className="relative flex justify-between h-100">
            <div className="w-100 h-100 px-3 flex justify-between">
              <span className="flex px-2 w-100">
                <FontAwesomeIcon icon={faUser} className="text-2xl w-25 bg-blue-200 rounded p-2"/>
                <p className="p-2">{capitalize}</p>
                <span className="flex justify-end w-100">
                  <button className="p-2 ml-2 flex justify-start hover:text-blue-600" onClick={handleLogout}> <FontAwesomeIcon icon={faRightFromBracket} className="text-xl"/> <p className="px-2">Salir</p></button>
                </span>
              </span>
            </div>
        </li>

      </ul>
    </section>
    </div>
    
    <div className={ isDesktop ? "relative w-4/5 h-auto" : "w-100"}>
      {/* 1{active === 'firstPage' && <h2 className='text-xl p-2 font-bold'>Entradas</h2>} */}
      {/* 1 */}{active === 'firstPage' && <Dashboard/>}
      {/* 2 */}{active === 'secondPage' && <h2 className='text-xl p-2 font-bold'>Estadísticas</h2>}
      {/* 3 */}{active === 'thirdPage' &&  <AdsManager/>}
      {/* 4 */}{active === 'fourthPage' && <Post/>}
      {/* 5 */}{active === 'fifthPage' &&  <h2 className='text-xl p-2 font-bold'>Medios</h2>}
      {/* 6 */}{active === 'sixthPage' &&  <h2 className='text-xl p-2 font-bold'>Usuarios</h2>}
      {/* 7 */}{active === 'seventhPage' && <h2 className='text-xl p-2 font-bold'>Ajustes</h2>}
    </div>
  </div>




    
  




  </section>
  )
};