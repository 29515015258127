import React, { useState, useEffect } from 'react';

function Weather() {
  const [weatherData, setWeatherData] = useState(null);
  const [city, setCity] = useState(''); // Estado para almacenar la ciudad ingresada por el usuario

  useEffect(() => {
    // Lógica para obtener datos del clima al cargar la aplicación
    const fetchData = async () => {
      const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${process.env.REACT_APP_API_KEY}&units=metric`);
      const data = await response.json();
      setWeatherData(data);
    };

    if (city !== '') {
      fetchData();
    }
  }, [city]);

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Weather App</h1>
        <input
          type="text"
          placeholder="Enter city"
          value={city}
          onChange={handleCityChange}
        />
        {weatherData && (
          <div className="weather-info">
            <h2>{weatherData.name}, {weatherData.sys.country}</h2>
            <div>{weatherData.weather[0].description}</div>
            <div>Temperature: {weatherData.main.temp}°C</div>
            <div>Feels like: {weatherData.main.feels_like}°C</div>
            <div>Humidity: {weatherData.main.humidity}%</div>
            <div>Wind Speed: {weatherData.wind.speed} m/s</div>
          </div>
        )}
      </header>
    </div>
  );
}

export default Weather;
