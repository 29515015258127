import { useMediaQuery } from 'react-responsive';
import CreateCorteCalf from "../../components/Admin/CreateCorteCalf";
import EditarCorteCalf from "../../components/Admin/EditarCorteCalf";

export default function AdsManager() {

  const isDesktop = useMediaQuery({ minWidth: 1024 });
 
  return (<section className={ isDesktop ? 'w-100 h-100 container bg-[#fefefe]' : 'top-60 relative'}>

  <div className='w-100 h-max w-100 m-0 outline outline-1 p-2'>
    <div className="w-max h-max">
      <h3 className='font-extralight text-xl font-sans underline underline-offset-2'>Cortes programados</h3>
      <h4 className="py-2 text-center">Output</h4>
    </div>

    <EditarCorteCalf/>
    <CreateCorteCalf/>
  </div>
  </section>)
}