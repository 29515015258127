function Publicidades (props) {
  const { imagen } = props;
  return (
    <div className='relative bg-[#FFF] md:h-36 outline outline-1 w-full mb-3 flex justify-center mx-auto mt-40'>
      <a className='w-100 flex justify-center' href="https://los40.com.ar">
        <img src={imagen} className="object-contain h-100" alt={'los-40-banner'}/>
      </a>
    </div>
  )
}

export default Publicidades