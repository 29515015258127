import { FacebookProvider, Comments } from 'react-facebook';

export default function FacebookComments (props) {

    const { link, category } = props;

    const replaced = link.replaceAll(' ', '-');
    let url = 'https://www.voxdiario.com/' + category + '/' + replaced;
    
    return (
        <>
            <FacebookProvider appId="401150635843138">
                <Comments href={url} mobile={true} width={100}/>
            </FacebookProvider>
        </>
    )
}