import React from 'react'

function Pulse() {
  return (
    <div className='animate-pulse'>
          <div className='h-52 w-100 bg-slate-700'>
              <div className='flex w-100 left-[45%] top-[40%] relative'>
          </div>
          </div>
          <div className='block w-50 h-2 bg-slate-700 mt-1 rounded-lg'></div>
          <div className='block w-100 h-6 bg-slate-700 mt-2 rounded-lg'></div>
          <div className='block w-100 h-4 bg-slate-700 mt-1 rounded-lg'></div>
          <div className='block w-100 h-4 bg-slate-700 mt-1 rounded-lg'></div>
          <div className='block w-25 h-8 bg-slate-700 mt-1 rounded-lg'></div>
        <ul>

        <li id="list-item" className='flex w-100 mt-3'>
          <div className="w-32 h-20 bg-slate-700 rounded"></div>
          <div>            
            <div className='block w-32 ml-2 h-5 rounded bg-slate-700 mt-3'></div>
            <div className='block w-52 ml-2 h-7 rounded bg-slate-700 mt-1'></div>
          </div>
        </li>

        <li id="list-item" className='flex w-100 mt-3'>
          <div className="w-32 h-20 bg-slate-700 rounded"></div>
          <div>            
            <div className='block w-32 ml-2 h-5 rounded bg-slate-700 mt-3'></div>
            <div className='block w-52 ml-2 h-7 rounded bg-slate-700 mt-1'></div>
          </div>
        </li>
        </ul>
      </div> 
  )
}

export default Pulse