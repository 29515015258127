import React from 'react'

function DashboardLoader() {
  return (
    <div className=' h-screen w-100 block p-4'>
      
      <span className='flex justify-center'>
        <b className='text-xl'>Cargando...</b>
      </span>

      <span className='flex justify-center p-1'>
        <div className="spinner-border animate-spin block w-6 h-6 border-2 rounded-full" role="status"></div>
      </span>

    </div>
  )
}

export default DashboardLoader