  import { useEffect, useState } from 'react';
  import { useMediaQuery } from 'react-responsive';
  import { useParams } from 'react-router-dom'
  import axios from 'axios';

  import EditPostDetail from './EditPostDetail';

function EditPostContainer() {
  const { post } = useParams();
  const [ myObj, setMyObj ] = useState([]);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  useEffect(() => {
    try{
    axios
      .get(process.env.REACT_APP_POSTS_API_URL)
      .then((response) => setMyObj(response.data))
    }
    catch{(err) => {console.log(err)}}
  },[])

  return (
  <section id="container" className={ isDesktop ? 'h-auto flex' : 'h-auto w-100'}>
  {/* EDIT SECTION */}
    <div className={isDesktop ? 'w-100 h-full flex justify-start mt-14' : 'w-100 h-full'}>
      {myObj.map((element) => element._id === post && 
        <EditPostDetail
        key={element._id}
        element={element}
        />
      )}
    </div>
  </section>
  )
}

export default EditPostContainer