import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';


function EditPostDetail(props) {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  
  const { element } = props;
  const options = [ { label: 'Política', value: 'Política'}, { label: 'Economía', value: 'Economía'}, { label: 'Sociedad', value: 'Sociedad'}, { label: 'Deportes', value: 'Deportes'}, { label: 'Policiales', value: 'Policiales'}, { label: 'Espectáculos', value: 'Espectáculos'}, { label: 'Tecnología', value: 'Tecnología'} ];
  const user = JSON.parse(localStorage.getItem("userInfo"));
  const navigate = useNavigate();
  
  /*<----- PUT HANDLING ----->*/
  const [ title, setTitle ] = useState('');
  const [ resume, setResume ] = useState('');
  const [ image, setImage ] = useState('');
  const [ body, setBody ] = useState('');

  const [preview, setPreview] = useState(false)
  
  useEffect(() => {
    
    setTitle(element.Entry_Title);
    setResume(element.Entry_Resume);
    setImage(element.Entry_Featured_Image);
    setBody(element.Entry_Body);
    
  },[])
  
    const handleTitle = (e)  => { e.preventDefault(); setTitle(e.target.value);};
    const handleResume = (e) => { e.preventDefault(); setResume(e.target.value);};
    const handleBody = (e) => { e.preventDefault(); setBody(e.target.value); }
  
  const handlePost = (e) => {
    e.preventDefault();
    
    const post = { Entry_Title: title, Entry_Resume: resume, Entry_Body: body, Entry_Featured_Image: image}
     fetch(process.env.REACT_APP_POSTS_API_URL + '/' + element._id
     , {
      method: 'PUT',
      postId: element._id,
      mode: "cors",
      redirect: 'follow' ,
      headers:  {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-ACCESS-TOKEN': user.token},
        body: JSON.stringify(post)})
      .then(response => { console.log('response', response); navigate(0); if(response.state == 200){ alert('Aprobado') }})
      .catch(e => { console.log('e', e) })
  }

  return (
    <>
      <form className={isDesktop ? 'w-50 flex justify-center' : 'w-100 flex justify-center p-4'}>
      {preview ? 
        <div className='w-full'>
          <h3>Vista Previa</h3>
          <h2>{data.Entry_Title}</h2>
          <h3>{data.Entry_Resume}</h3>
          <img src={data.Entry_Featured_Image}/>
          <article>{data.Entry_Body}</article>
          <article>
            <p className='' dangerouslySetInnerHTML={ { __html: data.Entry_Body}}></p>
          </article>
        </div>

      :
      <div className='text-center'>
        {/* TITLE */}
            <label className='text-xl p-2 font-bold' htmlFor='title'>
              <h3>Título</h3>
            </label>
            
            <div className={isDesktop ? '' : 'm-4'}>
              <input              
                className={`block border outline-none form-control p-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border-solid boreer-gray-300 rounded transition ease-in-out focus:text-gray-700 focus:bg-white focus:border-blue-600 ${isDesktop ? 'w-[500px]' : 'w-100'}`}
                type="text"
                required 
                name="title"
                defaultValue={element.Entry_Title}
                onChange={handleTitle}
              />
            </div>

        {/* RESUME */}
            <label className='text-xl p-2 font-bold' htmlFor="resume">
              <h3>Resumen</h3>
            </label>

            <div className={isDesktop ? '' : 'm-4'}>
              <input
                type="text"
                required 
                className={`block p-2 py-1.5 text-base font-normal text-gray700 bg-white bg-clip-pádding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-700 focus:outline-none ${isDesktop ? 'w-[500px]' : 'w-100 h-max'}`}
                name="resume"
                defaultValue={element.Entry_Resume}
                onChange={handleResume}
              />
            </div>

        {/* FEATURED IMAGE */}
            <label
              className='text-xl p-2 font-bold'
              htmlFor="featuredImage">
                <h3>Imagen destacada</h3>
            </label>

            <img
              src={element.Entry_Featured_Image}
              className=" h-72 relative left-0 m-auto"
            />

        {/* BODY */}
            <label className='text-xl p-2 font-bold'>
              <h3>Cuerpo de la entrada</h3>
            </label>

            <textarea
              className='w-100 h-[700px] outline outline-1 p-4'
              name="body"
              defaultValue={element.Entry_Body}
              onChange={handleBody}
            />

        {/* CATEGORY */}
            <ul className=''>
              <li>
                <span className=''>
                  <label className='text-xl py-2 px-2'> Categoría</label>
                  <select className='rounded p-1 border' defaultValue={element.Entry_Category} onSubmit={ (e) =>{ const selectedValue = e.target.value; setCategory(selectedValue)} }>
                    {options.map(item => {
                      return(<option key={item.label} value={item.value}>{item.value}</option>)
                    })}
                  </select>
                </span>
              </li>
            </ul>

            <div className='flex justify-center p-3'>
                <button 
                  type="button" 
                  className="inline-block p-4 px-6 py-2 border-2 border-blue-400 text-blue-400 font-medium text-md leading-tight uppercase rounded-full hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                  onClick={handlePost}>
                    Publicar
                </button>
              </div>
            </div>
      }

      </form>

    {/* PREVIEW SECTION */}
    <article className={isDesktop ? 'w-50 px-5 py-3 border mx-4 h-max' : 'w-100 p-4 border'} key={element._id}>
      <h2 className='text-3xl font-bold font-sans w-100 p-2 break-words'>{element.Entry_Title}</h2>
      <p className='text-xl font-sans text-neutral-500 p-2'>{element.Entry_Resume}</p>
      <img className='h-auto w-auto p-2' src={element.Entry_Featured_Image}/>
      <p className='whitespace-pre-wrap font-sans p-2' dangerouslySetInnerHTML={{ __html: body}}></p>
      <span className='flex justify-center gap-3 pt-4 pb-2'>
        <p className='border font-bold font-sans w-max p-2 px-2 rounded-md bg-zinc-50'># </p>
        <p className='border font-bold font-sans w-max p-2 px-2 rounded-md bg-zinc-50'># </p>
        <p className='border font-bold font-sans w-max p-2 px-2 rounded-md bg-zinc-50'># </p>
      </span> 
    </article>
    </>
  )
}

export default EditPostDetail