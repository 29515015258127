/* ESSENTIALS && MODULES */
import axios from 'axios';
import { faClock, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

/* ASSETS && COMPONENTS*/
import HeaderTest from '../../components/Home/HeaderTest.jsx';
import BannerLarge from './../../assets/Inmobiliarias/bannerGrande.svg';
import BannerSmall from './../../assets/Inmobiliarias/bannerChico.svg';
import Footer from './../../components/Footer/Footer'

export default function Inmobiliarias() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const result = await axios.get(process.env.REACT_APP_POSTS_API_URL);
      setData(result.data);
    };
    getData();
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
      <section className='max-h-max'>
        <HeaderTest />
        <div className='container-xl'>
          <div className='relative mb-3 flex justify-center mt-36 '>
            <img src={isDesktop ? BannerLarge : BannerSmall} className="object-cover max-h-max w-auto" alt={'Ghiggia Beneito Asesoría Inmobiliaria'} />
          </div>
        </div>

        <section className='container-xl h-full min-h-screen'>
          <p className={`text-neutral-400 text-sm ${isDesktop ? "px-12" : ""}`}>
            <Link to="/" className="hover:text-[#604594] duration-200"><FontAwesomeIcon icon={faHome} /> Inicio </Link>
            / Inmobiliarias
          </p>

          <div className={isMobile ? 'flex justify-center' : 'px-8'}>
            <h2 className="text-4xl font-semibold text-neutral-700 font-body my-8 w-max">Sector Inmobiliario</h2>
          </div>

          <section className="w-100 h-auto">
            {/* CONTAINER => MAP => IMAGEN => TITLE => RESUME */}
            {data.filter((e) => e.Entry_Category === 'Inmobiliarias').reverse().map((e) => (
              <>
                <div key={e._id} className={`md:flex ${isDesktop ? '' : 'h-auto'} p-3 mb-4 w-100 shadow rounded-md`}>

                  <Link to={'/Inmobiliarias/' + e.Entry_Title.replaceAll(' ', '-')} >
                    <img className={isDesktop ? 'h-80' : "filter hover:brightness-105 object-cover max-h-max w-auto saturate-105 delay-100 duration-150"} src={e.Entry_Featured_Image} alt={e.Entry_Title} />
                  </Link>

                <div className={isDesktop ? 'my-8 w-auto' : 'w-full'}>
                  <div className={isDesktop ? "flex relative px-3" : "flex p-1"}>
                    <FontAwesomeIcon className={ isDesktop ? 'text-xs pt-0.5 text-neutral-400 font-light' : 'text-neutral-700 text-xs mt-0.5'} icon={faClock} /> <p className={isDesktop ? "px-1 text-xs text-neutral-400 font-light" : 'text-black text-xs px-1'}>{e.createdAt.slice(0, 10).replaceAll('-', '/')}</p>
                  </div>

                  <div>
                    <Link to={'/Inmobiliarias/' + e.Entry_Title.replaceAll(' ', '-')} className="hover:text-[#604594] duration-200">
                      <h2 className={`text-md ${isDesktop ? 'md:text-2xl px-3' : 'p-1'} w-auto font-bold font-sans`}>{e.Entry_Title}</h2>
                    </Link>
                    <p className={isDesktop ? "px-3" : "p-1 text-sm"} id="card__preview-text">{e.Entry_Resume}</p>
                    <Link to={'/Inmobiliarias/' + e.Entry_Title.replaceAll(' ', '-')} className={isDesktop ? "block mt-2 px-3" : ""}>
                      <button className='p-2 text-white text-sm bg-[#604594]'>Leer más »</button>
                    </Link>
                  </div>

                </div>

                </div>
              <hr></hr>
              </>
            ))}

          </section>
        </section>
        <Footer/>
      </section>
  );
}
