import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faYoutube, faInstagram } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <footer className="w-full bg-[#2D2D2D] bottom-0 z-10 flex justify-center items-center text-white">
      <section className="container-xl mx-auto flex justify-between items-center py-4">
        <p className="text-xs md:text-sm">
          © {new Date().getFullYear()}. Todos los derechos reservados | <Link className="hover:text-purple-500" to="/">VoxDiario y Los 40 Neuquén FM 92.3 | Titular: Ultravox</Link>
        </p>
  
        <div id="social" className="flex justify-center">
          <Link className="px-2 cursor-pointer text-xl hover:text-cyan-200" to="#">
            <FontAwesomeIcon icon={faTwitter} />
            <span className="hidden md:block text-xs">Twitter</span>
          </Link>
          <Link className="px-2 cursor-pointer text-xl hover:text-red-500" to="#">
            <FontAwesomeIcon icon={faYoutube} />
            <span className="hidden md:block text-xs">Youtube</span>
          </Link>
          <Link className="px-2 cursor-pointer text-xl hover:text-pink-500" to="#">
            <FontAwesomeIcon icon={faInstagram} />
            <span className="hidden md:block text-xs">Instagram</span>
          </Link>
        </div>
      </section>
    </footer>
  );
}
