import axios from 'axios';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useState, useEffect } from 'react';

import Pulse from '../Others/Pulse';

/*<----- Publicidades ------>*/
function Widget() {

  const [ politics, setPolitics ] = useState([]);
  const [ economics, setEconomics ] = useState([]);
  const [ society, setSociety ] = useState([]); 

    useEffect(() => {

        const getPolitics = async () => {
          const result = await axios.get(process.env.REACT_APP_WIDGET_P);
          setPolitics(result.data);
        }
  
        const getEconomics = async () => {
          const result = await axios.get(process.env.REACT_APP_WIDGET_E);
          setEconomics(result.data);
        }
  
        const getSociety = async () => {
          const result = await axios.get(process.env.REACT_APP_WIDGET_S);
          setSociety(result.data);
        }
        
      getPolitics();
      getEconomics();
      getSociety();
    }, [])

    /* <----- Responsive Queries  -----> */
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
    const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (

  <section className={`h-100 w-100 py-2 flex pb-4`}>

    {/* CONTAINER */}
    <section className={isMobile || isTablet ? "block w-100" : 'flex w-100 justify-between'}>

    {/* Política */}
    <div className={isMobile || isTablet  ?  'h-max py-1 shadow px-2 rounded-xl mt-8' : 'md:h-50 w-100 md:w-50 h-full shadow px-1 py-1 rounded-xl'}>
      <div className='wrapper' id="wrapper">
        <ul>
          <li id="list-item" className=''>
            {/*  Titulo */}
            <div className={isDesktop ? 'bg-blue-700 w-full md:h-7 rounded' : 'bg-blue-700 rounded'}>
              {/* <h2 className='text-white w-[130px] h-10 font-bold text-xl text-center px-11 py-2'>Política</h2>   */}
              <h2 className='relative flex justify-center m-auto text-xl font-semibold text-white w-100'>Política</h2>
            </div>
            <div className='w-auto h-1 bg-white md:h-2'>

            </div>
            {/* Portada */}
            {politics.portada ?
            <div className='p-2'>
              <Link to={politics.portada.Entry_Category + '/' + politics.portada._id}>
                <img className='object-cover w-100 h-52' src={politics.portada.Entry_Featured_Image} alt={politics.portada.Entry_title + '-imagen'}/>
              </Link>
              <div className='m-1.5'>
                {/* <p className='px-1 pt-1 text-sm text-zinc-400'> <FontAwesomeIcon icon={faClock}/> {politicaMain.createdAt.slice(0, 10).replaceAll('-', '/')}</p> */}
                <Link to={politics.portada.Entry_Category + '/' + politics.portada._id}>
                  <p className='py-1 text-xl font-bold '>{politics.portada.Entry_Title}</p>
                </Link>
                <p className='mb-1' id="card__preview-widget">{politics.portada.Entry_Resume}</p>
                <Link to={politics.portada.Entry_Category + '/' + politics.portada._id}>
                  <button className='p-2 font-sans text-sm text-white bg-blue-700 rounded hover:bg-blue-700/80 hover:font-bold'>Leer más »</button>
                </Link>
              </div>  
            </div>
            : <Pulse/>
            }
          </li>

            {/* Label */}
            {politics.noticias ? politics.noticias.map((e) =>
              <li key={e._id} className="flex w-100 m-1.5 p-2" id="list-item">
                <Link to={e.Entry_Category + '/' + e._id} className="w-56">
                  <img className="object-cover h-20 w-100" src={e.Entry_Featured_Image} alt={e.Entry_Title + '-imagen'}/>
                </Link>

                <span className='w-100'>
                  {/* <p className='px-3 text-xs text-zinc-400'>  <FontAwesomeIcon icon={faClock}/>  {e.createdAt.slice(0, 10).replaceAll('-', '/')}</p> */}
                  <Link to={e.Entry_Category + '/' +e._id}>
                    <p className='px-3 py-1 text-sm font-bold w-100'>{e.Entry_Title}</p>
                  </Link>
                </span>
              </li>
            ) : <Pulse/>
            }
        </ul>
      </div>
    </div>

    {/* Economía */}
    
    {
      isMobile ? <hr className='relative top-[20px] h-3'/>
      :
      false
    }
    <div className={isDesktop ? 'h-auto w-100 md:w-50 md:ml-2 md:p-1 shadow px-2 py-1 rounded-xl' : 'h-auto py-1 shadow px-1 rounded-xl mt-8'}>
      <div className='wrapper' id="wrapper">
        <ul>
          <li id="list-item">

            {/* Titulo */}
            <div className='bg-[#FFDB58] w-full md:h-7 rounded'>
              <h2 className='relative flex justify-center m-auto text-xl font-semibold text-white hover:stroke-red-500 w-100'>Economía</h2>  
            </div>
            <div className='w-auto h-1 bg-white md:h-2'></div>

            {/* Portada */}
            {economics.portada ?
            <div className='p-2'>
              <div className='w-100 h-52'>
                <Link to={economics.portada.Entry_Category + '/' + economics.portada._id}> <img src={economics.portada.Entry_Featured_Image} className="object-cover h-100 w-100" alt={economics.portada.Entry_Title + "-imagen"}/> </Link>
              </div>
              <div className='m-1.5'>
                {/* <p className='text-xs py-1.5 text-zinc-400'> <FontAwesomeIcon icon={faClock}/>  {economiaMain.createdAt.slice(0, 10).replaceAll('-', '/')}</p> */}
                <Link to={economics.portada.Entry_Category + '/' + economics.portada._id}> <p className='py-1 text-xl font-bold'>{economics.portada.Entry_Title}</p> </Link>
                  <p className='mb-1.5' id="card__preview-widget">{economics.portada.Entry_Resume}</p>
                <Link to={economics.portada.Entry_Category + '/' + economics.portada._id}> <button className='text-sm font-sans  p-2 bg-[#FFDB58] hover:bg-[#ffdB58]/90 hover:font-bold text-white rounded'>Leer más »</button> </Link>
              </div>
            </div>
            :
            /*Esqueleto*/
            <Pulse/>
            }
          </li>

          {/* Label */}
          {economics.noticias ? economics.noticias.map((e) =>
          <li key={e._id} id="list-item" className='flex w-auto m-1.5 p-2'>
          <Link to={e.Entry_Category + '/' + e._id} className='w-56'><img src={e.Entry_Featured_Image} className="object-cover w-32 h-20 transition duration-75 hover:saturate-200" alt={e.Entry_Title + '-imagen'}></img></Link>
          <span className='w-100'>
            {/* <p className='px-1 text-xs text-zinc-400'> <FontAwesomeIcon icon={faClock}  /> {e.createdAt.slice(0, 10).replaceAll('-', '/')}</p> */}
            <Link to={e.Entry_Category + '/' + e._id}> <p className='px-1 py-1 text-sm font-bold'>{e.Entry_Title}</p> </Link>
          </span>
          </li>)
          : <Pulse/>
          }
        </ul>

      </div>
    </div>

    {/* SOCIEDAD */}

    {
      isMobile ? <hr className='relative top-[20px] h-3'/>
      :
      false
    }
    
    <div className={isDesktop ? 'md:h-50 w-100 md:w-50 md:ml-2 md:p-1 h-full shadow px-2 py-1 rounded-xl' : 'h-max py-1 shadow px-1 rounded-xl mt-8'}>
    
      <div className='wrapper' id="wrapper">
        <ul>
          <li id="list-item">
          {/* Titulo */}
          <div className='flex bg-gray-500 rounded w-100 md:h-8'>
            <h2 className='relative flex justify-center m-auto text-xl font-semibold text-white w-100'>Sociedad</h2>  
          </div>
          <div className='h-1 bg-white md:h-2'></div>
          {/* Portada */}
          {society.portada ?
          <div className='p-2'>
            <div className='w-100 h-52'>
              <Link to={society.portada.Entry_Category + '/' + society.portada._id}> <img src={society.portada.Entry_Featured_Image} className="object-cover h-100 w-100" alt={society.portada.Entry_Title + "-imagen"}/> </Link>
            </div>
            <div className='m-1.5'>
              {/* <p className='text-xs py-1.5 text-zinc-400'> <FontAwesomeIcon icon={faClock}/>  {society.portada.createdAt.slice(0, 10).replaceAll('-', '/')}</p> */}
              <Link to={society.portada.Entry_Category + '/' + society.portada._id}> <p className='py-1 text-xl font-bold'>{society.portada.Entry_Title}</p> </Link>
                <p className='mb-2' id="card__preview-widget">{society.portada.Entry_Resume}</p>
              <Link to={society.portada.Entry_Category + '/' + society.portada._id}> <button className='p-2 px-2 font-sans text-sm text-white bg-gray-500 rounded hover:bg-gray-500/80 hover:font-bold'>Leer más »</button> </Link>
            </div>
          </div> 
          :
          /*Esqueleto*/
          <Pulse/>
          }
          </li>

          {/* Label */}
          {society.noticias ? society.noticias.map((e) =>
          <li key={e._id} id="list-item" className='flex w-100 m-1.5 p-2'>
            <Link to={e.Entry_Category + '/' + e._id} className='w-56'><img src={e.Entry_Featured_Image} className="object-cover w-32 h-20 transition duration-75 hover:saturate-200" alt={e.Entry_Title + '-imagen'}></img></Link>
            <span className='w-100'>
              {/* <p className='px-1 text-xs text-zinc-400'> <FontAwesomeIcon icon={faClock}  /> {e.createdAt.slice(0, 10).replaceAll('-', '/')}</p> */}
              <Link to={e.Entry_Category + '/' + e._id}> <p className='px-1 py-1 text-sm font-bold'>{e.Entry_Title}</p> </Link>
            </span>
          </li>)
          : null 
          }
        </ul>
      </div>
    </div>
  </section>
</section>
)}

export default Widget