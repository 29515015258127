import axios from 'axios';
import { Link } from "react-router-dom"
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from "react";

  /*Publicidades*/
  import Contacto from './../../assets/Calf/contactonqn.png'
  import LaSeptima from './../../assets/Calf/LaSeptimaBG.png'
  import ZetaBirra from './../../assets/data/zeta.gif'
  import CortesCarousel from "../Carousel/CortesCarousel";
  import newBpn from './../../assets/data/bpn1.jpg';
  import loteo from './../../assets/banners/loteo.gif'



export default function (props) {


  const [posts, setPosts] = useState([]);
  const {cortes} = props;
  
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1024 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });

  useEffect(() => {
    const getData = async () => {
      const result = await axios.get(process.env.REACT_APP_COMPONENT_URI);
      setPosts(result.data.reverse());
    }
    getData();
    
  }, [])

  let content = posts.map((e) => e);
  let reverse = content.slice(2).reverse();
  let arr = [0,1,2,3,4,5];

  return(
  <section className="block mt-4 lg:flex md:shadow-inner">

  <section className="md:w-2/3 block py-1.5 justify-between h-max md:p-4">


    <CortesCarousel cortes={cortes}/>
    
    <br/>



    <div className="w-100 h-32 outline outline-1 outline-black hover:outline-blue-800">
      <a href="https://www.neuquencapital.gov.ar/" target="_blank">
        <img src={loteo} className="h-100 w-full flex justify-center m-auto" alt="Publicidad Municipalidad de Neuquén"/>
      </a>
    </div>
    
    <br/>

    <div className="w-100 h-32">
      <a href="http://mutualbpn.com.ar/" target="_blank" className="outline-black hover:outline-blue-800">
        <img src={newBpn} className="outline outline-1 h-32 object-contain w-100" alt="Publicidad Mutual BPN"/>
      </a>
    </div>

    <br/>

    <br/>

    <div className="w-100 bg-black h-32 my-2">
      <a href="https://www.instagram.com/zeta.birra/" target="_blank">
        <img src={ZetaBirra} className="outline outline-1 h-32 object-contain outline-black w-100" alt="Publicidad Zeta Birra"/>
      </a>
    </div>

    <br/>

    <div className="w-100 bg-[#2D2D2D] my-2 outline outline-1 outline-black">
      <a href="https://laseptimaradio.com/" target="_blank">
        <img src={LaSeptima} className="h-32 flex m-auto" alt="Imagen La Séptima Radio con vos"/>
      </a>
    </div>

  </section>

  <hr className="mt-4"/>

  <section id="item-list-wrap" className="h-auto inline md:flex w-100 flex-wrap justify-around px-2">
      {reverse.length > 0 ? 
      reverse.map((e) => <div className={isDesktop ? "md:w-2/4 h-auto p-3.5" : 'shadow-md p-2.5 h-auto w-100 my-3 rounded'} key={e._id}>
        
        <Link to={e.Entry_Category + '/' + e._id}>
          <img
            src={e.Entry_Featured_Image}
            className="saturate-150 object-cover object-top w-100 h-[200px]"
            alt={e.Entry_Title}
            />
            </Link>

        <span className="relative -top-12 float-right right-4 bg-[#604595] font-semibold p-2 text-xs text-white">
          {e.Entry_Category}
        </span>

        {/* <p className="text-xs pt-1.5 text-neutral-700 font-light"> <FontAwesomeIcon icon={faClock} /> {e.createdAt.slice(0, 10).replaceAll('-', '/')} </p> */}
          <Link
            to={e.Entry_Category + '/' + e._id}
            className="hover:text-[#462053]"
            >
            <h3 className={isMobile || isTablet ? 'text-md font-bold py-1.5 w-auto' : "text-xl font-bold py-1.5 w-auto"}> {e.Entry_Title}.</h3>
          </Link>


        <p className="hidden md:block" id="card__preview-text">{e.Entry_Resume}</p>


      </div>)
    : 
    
    arr.map((e) =>  <div className="md:w-2/4 h-auto p-1.5 mb-36" key={e}>
    <div className="w-100 h-[200px] bg-slate-700/95 animate-pulse">
      <div className="relative top-48">
        <div className="h-3 w-1/6 relative top-4 bg-slate-600"></div>
        <div className="h-6 w-11/12 mt-6 mb-1 bg-slate-600"> </div>
        <div className="h-4 w-100 mt-1 bg-slate-600"></div>
        <div className="h-4 mt-1 bg-slate-600"></div>
        <div className="h-4 mt-1 bg-slate-600"></div>
        <div className="h-4 w-64 mt-1 bg-slate-600"></div>
      </div>
    </div>
  </div>
  ) 
    }

  </section>
    </section>
  )
};